@charset "UTF-8";
/* Custom variables below */
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important;
  }
  @page {
    size: A4 portrait;
  }
  header .header-left .menu-mobile,
  header .header-right,
  header .sub-header-wrap {
    display: none !important;
  }
  header .header-wrap {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  main {
    background-color: #ffffff !important;
    break-after: avoid !important;
    page-break-after: avoid !important;
    overflow: hidden;
  }
  main::after {
    content: "© CALVEZ ÉLECTRICITÉ | Tous droits réservés";
    display: block;
    margin-bottom: 0 !important;
    text-align: center;
    break-after: avoid !important;
    page-break-after: avoid !important;
  }
  main .btn-share,
  main .carousel-indicators,
  main .info-container__shop-list,
  main .info-container__functionalities-prod,
  main .carousel-control-next,
  main .carousel-control-prev,
  main .btn__moreInfo,
  main button,
  main .iframe,
  main a.btn,
  main .video,
  main .arrowLink,
  main .twitter-tweet,
  main .block-slider {
    display: none !important;
  }
  main p {
    font-size: 12pt !important;
  }
  main a:not(.breadcrumb-navigation .breadcrumb .breadcrumb-item a, .information-block a, .author-block a, .quote a, .product-img-wrapper a) {
    color: #17161D !important;
    text-decoration: underline !important;
  }
  main a:not(.breadcrumb-navigation .breadcrumb .breadcrumb-item a, .information-block a, .author-block a, .quote a, .product-img-wrapper a)::after {
    content: " (" attr(href) ")";
  }
  main .information-block a,
  main .author-block a,
  main .quote a {
    text-decoration: none !important;
  }
  main .information-block a::after,
  main .author-block a::after,
  main .quote a::after {
    content: " (" attr(href) ")";
    text-decoration: underline !important;
  }
  main .breadcrumb-navigation .breadcrumb .breadcrumb-item a {
    text-decoration: none !important;
  }
  main .cloud-spacer {
    max-height: 20px;
    min-height: 20px !important;
  }
  main .carousel-inner {
    margin: auto !important;
  }
  main #slider {
    justify-content: start !important;
  }
  main .accordion-container #more-charac, main .accordion-container button {
    display: none !important;
  }
  main .associated-products {
    page-break-before: always !important;
    break-before: always !important;
  }
  main .associated-products .associated-products-list {
    overflow: visible;
    border: none;
  }
  main .associated-products .associated-products-list .control-prev-products,
  main .associated-products .associated-products-list .control-next-products {
    display: none !important;
  }
  main .associated-products .associated-products-list .horizontal-scroll-wrapper {
    flex-wrap: wrap;
  }
  main .associated-products .product-overview .card-body .product-img-wrapper a::after {
    content: "";
    display: none !important;
  }
  main .associated-products .product-overview .card-body .name-wrap a::after {
    content: "";
    display: none !important;
  }
  main .associated-products .product-overview .card-body .product__shop-actions {
    display: none !important;
  }
  main .quote__intro {
    color: #17161D !important;
  }
  main .quote__wrapper {
    border-left: none;
  }
  main .quote__wrapper .quote__imagewrapper {
    max-width: 60mm !important;
  }
  main .quote__wrapper .quote__textwrapper {
    max-width: 145mm !important;
  }
  main .bb-content.figure .contentwrapper img {
    max-width: 270px !important;
    height: auto !important;
  }
  main .card-block .card figure {
    max-height: 60mm !important;
    padding-top: 50mm !important;
  }
  main .card-block .card figure .contentwrapper {
    max-height: 60mm !important;
  }
  main .card-block .card figure .contentwrapper img {
    top: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  main .card-block .card .row.no-gutter {
    column-count: 2 !important;
    column-width: auto !important;
  }
  main .card-block .card .row.no-gutter .col-lg-6 {
    display: inline !important;
  }
  main .card-block .card .row.no-gutter .col-lg-6 figure {
    max-height: 65mm !important;
    padding-top: 80mm !important;
  }
  main .card-block .card .row.no-gutter .col-lg-6 figure .contentwrapper {
    max-height: unset !important;
  }
  main .card-block .card .row.no-gutter .col-lg-6 figure .contentwrapper img {
    max-width: 115mm !important;
    left: 0% !important;
    transform: translateX(0%) !important;
  }
  main .card-block .card .row.no-gutter .col-lg-6 .card-body {
    max-width: 80mm !important;
  }
  main .dowload-pdf-block .download-pdf-wrapper .column-image figure,
  main .dowload-pdf-block .download-pdf-wrapper .column-image .contentwrapper,
  main .dowload-pdf-block .download-pdf-wrapper .column-image img {
    max-height: 50mm !important;
  }
  main .dowload-pdf-block .download-pdf-wrapper .column-image figure {
    padding-top: 85% !important;
  }
  main .dowload-pdf-block .download-pdf-wrapper .column-image img {
    width: auto !important;
  }
  main .dowload-pdf-block .download-pdf-wrapper .column-content .btn-download {
    display: block !important;
    background-color: transparent !important;
    border: none !important;
    text-decoration: none !important;
  }
  main .dowload-pdf-block .download-pdf-wrapper .column-content .btn-download::after {
    content: " (" attr(href) ")";
    text-decoration: underline !important;
  }
  main .dowload-pdf-block.promotion-download .column-image {
    max-width: 100mm !important;
    margin-top: 8mm !important;
  }
  main .dowload-pdf-block.promotion-download .column-image figure {
    padding-top: 50mm !important;
  }
  main .dowload-pdf-block.promotion-download .column-image figure img {
    min-width: auto !important;
  }
  footer {
    display: none !important;
  }
}
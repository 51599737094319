@import 'custom_variables';
@media print {
    * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;
    }

    @page {
        size: A4 portrait;
    }

    header {
        .header-left .menu-mobile, 
        .header-right,
        .sub-header-wrap {
            display: none !important;
        }

        .header-wrap {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    main {
        background-color: $white !important;
        break-after: avoid !important;
        page-break-after: avoid !important;
        overflow: hidden;
        
        &::after {
            content: "© CALVEZ ÉLECTRICITÉ | Tous droits réservés";
            display: block;
            margin-bottom: 0 !important;
            text-align: center;
            break-after: avoid !important;
            page-break-after: avoid !important;
        }

        .btn-share, 
        .carousel-indicators,
        .info-container__shop-list,
        .info-container__functionalities-prod,
        .carousel-control-next,
        .carousel-control-prev,
        .btn__moreInfo,
        button,
        .iframe,
        a.btn,
        .video,
        .arrowLink,
        .twitter-tweet,
        .block-slider {
            display: none !important;
        }

        p {
            font-size: 12pt !important;
        }

        a:not(.breadcrumb-navigation .breadcrumb .breadcrumb-item a, .information-block a, .author-block a, .quote a, .product-img-wrapper a) {
            color: $text-color !important;
            text-decoration: underline !important;

            &::after {
                content: " (" attr(href) ")";
            }
        }

        // information Block | Author block | Quote Block
        .information-block a, 
        .author-block a,
        .quote a {
            text-decoration: none !important;

            &::after {
                content: " (" attr(href) ")";
                text-decoration: underline !important;
            }
        }

        // Fil d'ariane
        .breadcrumb-navigation {
            .breadcrumb {
                .breadcrumb-item {
                    a {
                        text-decoration: none !important;
                    }
                }
            }
        }

        // Bloc Espace
        .cloud-spacer {
            max-height: 20px;
            min-height: 20px !important;
        }

        // Slider Page produit
        .carousel-inner {
            margin: auto !important;
        }

        #slider {
            justify-content: start !important;
        }

        // Accordeon Page produit
        .accordion-container {
            #more-charac, button {
                display: none !important;
            }
        }

        // Produits associés Page produit
        .associated-products {
            page-break-before: always !important;
            break-before: always !important;

            .associated-products-list {
                overflow: visible;
                border: none;

                .control-prev-products,
                .control-next-products {
                    display: none !important;
                }

                .horizontal-scroll-wrapper {
                    flex-wrap: wrap;
                }
            }

            .product-overview {
                .card-body {
                    .product-img-wrapper {
                        a {
                            &::after {
                                content: '';
                                display: none  !important;
                            }
                        }
                    }

                    .name-wrap {
                        a {
                            &::after {
                                content: '';
                                display: none !important;
                            }
                        }
                    }

                    .product__shop-actions {
                        display: none !important;
                    }
                } 
            }
        }

        // Bloc Citation
        .quote {
            &__intro {
                color: $text-color !important;
            }
            &__wrapper {
                border-left: none;

                .quote {
                    &__imagewrapper {
                        max-width: 60mm !important;
                    }

                    &__textwrapper {
                        max-width: 145mm !important;
                    }
                }
            }
        }

        // Bloc Image
        .bb-content {
            &.figure {
                .contentwrapper {
                    img {
                        max-width: 270px !important;
                        height: auto !important;
                    }
                }
            }
        }

        // Bloc Card
        .card-block {
            .card {
                figure {
                    max-height: 60mm !important;
                    padding-top: 50mm !important;
                    .contentwrapper {
                        max-height: 60mm !important;
                        img {
                            top: 0 !important;
                            left: 50% !important;
                            transform: translateX(-50%) !important;
                        }
                    }
                }

                .row.no-gutter {
                    column-count: 2 !important;
                    column-width: auto !important;
                    .col-lg-6 {
                        display: inline !important;
                        figure {
                            max-height: 65mm !important;
                            padding-top: 80mm !important;
                            .contentwrapper {
                                max-height: unset !important;
                                img {
                                    max-width: 115mm !important;
                                    left: 0% !important;
                                    transform: translateX(0%) !important;
                                }
                            }
                        }

                        .card-body {
                            max-width: 80mm !important;
                        }
                    }
                }

            }
        }

        // Download Block
        .dowload-pdf-block {
            .download-pdf-wrapper {
                .column-image {
                    figure, 
                    .contentwrapper, 
                    img {
                        max-height: 50mm !important;
                    }

                    figure {
                        padding-top: 85% !important;
                    }

                    img {
                        width: auto !important;
                    }
                }

                .column-content {
                    .btn-download {
                        display: block !important;
                        background-color: transparent !important;
                        border: none !important;
                        text-decoration: none !important;

                        &::after {
                            content: " (" attr(href) ")";
                            text-decoration: underline !important;
                        }
                    }
                }
            }

            &.promotion-download {
                .column-image {
                    max-width: 100mm !important;
                    margin-top: 8mm !important;

                    figure {
                        padding-top: 50mm !important;

                        img {
                            min-width: auto !important;
                        }
                    }
                }
            }
        }
    }

    footer {
        display: none !important;
    }
}